import "core-js/modules/web.dom-collections.iterator.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { fetchRelationships } from 'soapbox/actions/accounts';
import { importFetchedAccounts } from 'soapbox/actions/importer';
import { getLinks } from 'soapbox/api';
import { useApi, useAppDispatch, useFeatures } from 'soapbox/hooks';
import { removePageItem } from '../utils/queries';
const suggestionKeys = {
  suggestions: ['suggestions']
};

const mapSuggestedProfileToAccount = suggestedProfile => ({
  id: suggestedProfile.account_id,
  avatar: suggestedProfile.account_avatar,
  avatar_static: suggestedProfile.account_avatar,
  acct: suggestedProfile.acct,
  display_name: suggestedProfile.display_name,
  note: suggestedProfile.note,
  verified: suggestedProfile.verified
});

const useSuggestions = () => {
  var _result$data;

  const api = useApi();
  const dispatch = useAppDispatch();
  const features = useFeatures();

  const getV2Suggestions = async pageParam => {
    var _getLinks$refs$find;

    const endpoint = (pageParam === null || pageParam === void 0 ? void 0 : pageParam.link) || '/api/v2/suggestions';
    const response = await api.get(endpoint);
    const hasMore = !!response.headers.link;
    const nextLink = (_getLinks$refs$find = getLinks(response).refs.find(link => link.rel === 'next')) === null || _getLinks$refs$find === void 0 ? void 0 : _getLinks$refs$find.uri;
    const accounts = response.data.map(_ref => {
      let {
        account
      } = _ref;
      return account;
    });
    const accountIds = accounts.map(account => account.id);
    dispatch(importFetchedAccounts(accounts));
    dispatch(fetchRelationships(accountIds));
    return {
      result: response.data.map(x => _objectSpread(_objectSpread({}, x), {}, {
        account: x.account.id
      })),
      link: nextLink,
      hasMore
    };
  };

  const getTruthSuggestions = async pageParam => {
    var _getLinks$refs$find2;

    const endpoint = (pageParam === null || pageParam === void 0 ? void 0 : pageParam.link) || '/api/v1/truth/carousels/suggestions';
    const response = await api.get(endpoint);
    const hasMore = !!response.headers.link;
    const nextLink = (_getLinks$refs$find2 = getLinks(response).refs.find(link => link.rel === 'next')) === null || _getLinks$refs$find2 === void 0 ? void 0 : _getLinks$refs$find2.uri;
    const accounts = response.data.map(mapSuggestedProfileToAccount);
    dispatch(importFetchedAccounts(accounts, {
      should_refetch: true
    }));
    return {
      result: response.data.map(x => _objectSpread(_objectSpread({}, x), {}, {
        account: x.account_id
      })),
      link: nextLink,
      hasMore
    };
  };

  const getSuggestions = pageParam => {
    if (features.truthSuggestions) {
      return getTruthSuggestions(pageParam);
    } else {
      return getV2Suggestions(pageParam);
    }
  };

  const result = useInfiniteQuery(suggestionKeys.suggestions, _ref2 => {
    let {
      pageParam
    } = _ref2;
    return getSuggestions(pageParam);
  }, {
    keepPreviousData: true,
    getNextPageParam: config => {
      if (config !== null && config !== void 0 && config.hasMore) {
        return {
          nextLink: config === null || config === void 0 ? void 0 : config.link
        };
      }

      return undefined;
    }
  });
  const data = (_result$data = result.data) === null || _result$data === void 0 ? void 0 : _result$data.pages.reduce((prev, curr) => [...prev, ...curr.result], []);
  return _objectSpread(_objectSpread({}, result), {}, {
    data: data || []
  });
};

const useDismissSuggestion = () => {
  const api = useApi();
  return useMutation(accountId => api.delete("/api/v1/suggestions/".concat(accountId)), {
    onMutate(accountId) {
      removePageItem(suggestionKeys.suggestions, accountId, (o, n) => o.account_id === n);
    }

  });
};

function useOnboardingSuggestions() {
  var _result$data2;

  const api = useApi();
  const dispatch = useAppDispatch();

  const getV2Suggestions = async pageParam => {
    var _getLinks$refs$find3;

    const link = (pageParam === null || pageParam === void 0 ? void 0 : pageParam.link) || '/api/v2/suggestions';
    const response = await api.get(link);
    const hasMore = !!response.headers.link;
    const nextLink = (_getLinks$refs$find3 = getLinks(response).refs.find(link => link.rel === 'next')) === null || _getLinks$refs$find3 === void 0 ? void 0 : _getLinks$refs$find3.uri;
    const accounts = response.data.map(_ref3 => {
      let {
        account
      } = _ref3;
      return account;
    });
    const accountIds = accounts.map(account => account.id);
    dispatch(importFetchedAccounts(accounts));
    dispatch(fetchRelationships(accountIds));
    return {
      data: response.data,
      link: nextLink,
      hasMore
    };
  };

  const result = useInfiniteQuery(['suggestions', 'v2'], _ref4 => {
    let {
      pageParam
    } = _ref4;
    return getV2Suggestions(pageParam);
  }, {
    keepPreviousData: true,
    getNextPageParam: config => {
      if (config.hasMore) {
        return {
          link: config.link
        };
      }

      return undefined;
    }
  });
  const data = (_result$data2 = result.data) === null || _result$data2 === void 0 ? void 0 : _result$data2.pages.reduce((prev, curr) => [...prev, ...curr.data], []);
  return _objectSpread(_objectSpread({}, result), {}, {
    data
  });
}

export { useOnboardingSuggestions, useSuggestions, useDismissSuggestion };