function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import "core-js/modules/web.dom-collections.iterator.js";
import { queryClient } from 'soapbox/queries/client';

/** Flatten paginated results into a single array. */
const flattenPages = queryInfo => {
  var _queryInfo$data;

  return (_queryInfo$data = queryInfo.data) === null || _queryInfo$data === void 0 ? void 0 : _queryInfo$data.pages.reduce((prev, curr) => [...prev, ...curr.result], []);
};
/** Traverse pages and update the item inside if found. */


const updatePageItem = (queryKey, newItem, isItem) => {
  queryClient.setQueriesData(queryKey, data => {
    if (data) {
      const pages = data.pages.map(page => {
        const result = page.result.map(item => isItem(item, newItem) ? newItem : item);
        return _objectSpread(_objectSpread({}, page), {}, {
          result
        });
      });
      return _objectSpread(_objectSpread({}, data), {}, {
        pages
      });
    }
  });
};
/** Insert the new item at the beginning of the first page. */


const appendPageItem = (queryKey, newItem) => {
  queryClient.setQueryData(queryKey, data => {
    if (data) {
      const pages = [...data.pages];
      pages[0] = _objectSpread(_objectSpread({}, pages[0]), {}, {
        result: [...pages[0].result, newItem]
      });
      return _objectSpread(_objectSpread({}, data), {}, {
        pages
      });
    }
  });
};
/** Remove an item inside if found. */


const removePageItem = (queryKey, itemToRemove, isItem) => {
  queryClient.setQueriesData(queryKey, data => {
    if (data) {
      const pages = data.pages.map(page => {
        const result = page.result.filter(item => !isItem(item, itemToRemove));
        return _objectSpread(_objectSpread({}, page), {}, {
          result
        });
      });
      return _objectSpread(_objectSpread({}, data), {}, {
        pages
      });
    }
  });
};

export { flattenPages, updatePageItem, appendPageItem, removePageItem };