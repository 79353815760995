function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { useQuery } from '@tanstack/react-query';
import { getProvider } from 'soapbox/features/ads/providers';
import { useAppDispatch } from 'soapbox/hooks';
import { normalizeAd } from 'soapbox/normalizers';
import { isExpired } from 'soapbox/utils/ads';
export default function useAds() {
  var _result$data;

  const dispatch = useAppDispatch();

  const getAds = async () => {
    return dispatch(async (_, getState) => {
      const provider = await getProvider(getState);

      if (provider) {
        return provider.getAds(getState);
      } else {
        return [];
      }
    });
  };

  const result = useQuery(['ads'], getAds, {
    placeholderData: []
  }); // Filter out expired ads.

  const data = (_result$data = result.data) === null || _result$data === void 0 ? void 0 : _result$data.map(normalizeAd).filter(ad => !isExpired(ad));
  return _objectSpread(_objectSpread({}, result), {}, {
    data
  });
}