/** Picks the next item every iteration. */
const linearAlgorithm = (items, iteration, rawOpts) => {
  const opts = normalizeOpts(rawOpts);
  const itemIndex = items ? Math.floor(iteration / opts.interval) % items.length : 0;
  const item = items ? items[itemIndex] : undefined;
  const showItem = (iteration + 1) % opts.interval === 0;
  return showItem ? item : undefined;
};

const normalizeOpts = opts => {
  const {
    interval
  } = opts && typeof opts === 'object' ? opts : {};
  return {
    interval: typeof interval === 'number' ? interval : 20
  };
};

export { linearAlgorithm };