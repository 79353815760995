import seedrandom from 'seedrandom';

/**
 * Algorithm to display items per-page.
 * One item is randomly inserted into each page within the index range.
 */
const abovefoldAlgorithm = (items, iteration, rawOpts) => {
  const opts = normalizeOpts(rawOpts);
  /** Current page of the index. */

  const page = Math.floor(iteration / opts.pageSize);
  /** Current index within the page. */

  const pageIndex = iteration % opts.pageSize;
  /** RNG for the page. */

  const rng = seedrandom("".concat(opts.seed, "-page-").concat(page));
  /** Index to insert the item. */

  const insertIndex = Math.floor(rng() * (opts.range[1] - opts.range[0])) + opts.range[0];

  if (pageIndex === insertIndex) {
    return items[page % items.length];
  }
};

const normalizeOpts = opts => {
  const {
    seed,
    range,
    pageSize
  } = opts && typeof opts === 'object' ? opts : {};
  return {
    seed: typeof seed === 'string' ? seed : '',
    range: Array.isArray(range) ? [Number(range[0]), Number(range[1])] : [2, 6],
    pageSize: typeof pageSize === 'number' ? pageSize : 20
  };
};

export { abovefoldAlgorithm };